import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { theme } from 'theme'

import { Container } from '@/components/_layout/Container'
import { GenericSlice } from '@/components/_layout/GenericSlice'
import { BookingIllu } from '@/components/_svg/illustrations/BookingIllu'
import { ComputerIllu } from '@/components/_svg/illustrations/ComputerIllu'
import { TabletIllu } from '@/components/_svg/illustrations/TabletIllu'
import { ILessonDecorated } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  lesson?: ILessonDecorated
}

export const LessonOnlineAboutSlice: React.FC<IProps> = ({ lesson }) => {
  const intl = useIntl()

  return (
    <GenericSlice
      title={intl.formatMessage({
        defaultMessage: 'Comment ça marche ?',
        description: 'LessonOnlineAboutSlice: title',
      })}
      sectionTitle={intl.formatMessage({
        defaultMessage: "Mode d'emploi",
        description: 'LessonOnlineAboutSlice: section title',
      })}
    >
      <StyledContainer $column={lesson?.webinarZoomMeeting ? 2 : 3}>
        <Column>
          <AboutIlluWrapper $number={'1'} $color={theme.color.mandarine}>
            <StyledBookingIllu />
          </AboutIlluWrapper>
          <AboutColumnContentWrapper>
            <FormattedMessage
              tagName={AboutColumnTitle}
              defaultMessage="Je m'inscris et je choisis un cours"
              description="LessonOnlineAboutSlice: column1 title"
            />
            <FormattedMessage
              tagName={AboutColumnContent}
              defaultMessage="Matin, midi ou soir, choisissez le cours qui vous plait parmi nos nombreuses disciplines."
              description="LessonOnlineAboutSlice: column1 content"
            />
          </AboutColumnContentWrapper>
        </Column>

        <Column>
          <AboutIlluWrapper $number={'2'} $color={theme.color.pinkDark}>
            <StyledComputerIllu />
          </AboutIlluWrapper>
          <AboutColumnContentWrapper>
            <FormattedMessage
              tagName={AboutColumnTitle}
              defaultMessage="J'assiste au cours en live"
              description="LessonOnlineAboutSlice: column2 title"
            />
            <FormattedMessage
              tagName={AboutColumnContent}
              defaultMessage="Connectez-vous à l'heure indiquée et retrouvez votre professeur pour pratiquer depuis chez vous."
              description="LessonOnlineAboutSlice: column2 content"
            />
          </AboutColumnContentWrapper>
        </Column>

        {lesson?.webinarZoomMeeting ? null : <ColumnReplay />}
      </StyledContainer>
    </GenericSlice>
  )
}

const ColumnReplay: React.FC = () => (
  <Column>
    <AboutIlluWrapper $number={'3'} $color={theme.color.pink}>
      <StyledTabletIllu />
    </AboutIlluWrapper>
    <AboutColumnContentWrapper>
      <FormattedMessage
        tagName={AboutColumnTitle}
        defaultMessage="Je peux refaire la séance en replay"
        description="LessonOnlineAboutSlice: column3 title"
      />
      <FormattedMessage
        tagName={AboutColumnContent}
        defaultMessage="Vous avez manqué le cours ou souhaitez le refaire ? Retrouvez le en replay à tout moment !"
        description="LessonOnlineAboutSlice: column3 content"
      />
    </AboutColumnContentWrapper>
  </Column>
)

const StyledContainer = styled(Container)<{ $column: number }>`
  display: grid;
  gap: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    gap: 8.33%;
    grid-template-columns: ${({ $column }) =>
      Array.from(Array($column))
        .map(() => '1fr')
        .join(' ')};
    justify-items: center;
  }
`
const Column = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex-direction: column;
    text-align: center;
  }
`
export const AboutIlluWrapper = styled.div<{ $number: string; $color: string }>`
  align-items: center;
  aspect-ratio: 3/4;
  background-color: ${({ theme }) => theme.color.beigeLight};
  border-radius: 192px;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.s};
  position: relative;
  width: 100px;

  @supports not (aspect-ratio: 1) {
    padding: ${({ theme }) => theme.spacing.m} 0;
  }

  &::after {
    ${({ theme }) => theme.typo.h1};
    align-items: center;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 50%;
    bottom: -${({ theme }) => theme.spacing.s};
    box-sizing: border-box;
    color: ${({ $color }) => $color};
    content: '${({ $number }) => $number}';
    display: flex;
    height: ${({ theme }) => theme.spacing.ml};
    justify-content: center;
    line-height: 0;
    padding-bottom: ${({ theme }) => theme.spacing.xs};
    position: absolute;
    width: ${({ theme }) => theme.spacing.ml};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: 192px;

    @supports not (aspect-ratio: 1) {
      padding: ${({ theme }) => theme.spacing.xxxl} 0;
    }

    &::after {
      bottom: -${({ theme }) => theme.spacing.ms};
      height: ${({ theme }) => theme.spacing.xl};
      padding-bottom: ${({ theme }) => theme.spacing.s};
      width: ${({ theme }) => theme.spacing.xl};
    }
  }
`
export const aboutIlluStyle = css`
  max-height: ${({ theme }) => theme.spacing.xxl};
  max-width: ${({ theme }) => theme.spacing.xxl};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    max-height: inherit;
    max-width: inherit;
  }
`
const StyledBookingIllu = styled(BookingIllu)`
  ${aboutIlluStyle}
`
const StyledComputerIllu = styled(ComputerIllu)`
  ${aboutIlluStyle}
`
const StyledTabletIllu = styled(TabletIllu)`
  ${aboutIlluStyle}
`
export const AboutColumnContentWrapper = styled.div`
  flex: 1;
`
export const AboutColumnTitle = styled.p`
  ${({ theme }) => theme.typo.h4}
  ${({ theme }) => theme.typo.bold}

  margin-bottom: ${({ theme }) => theme.spacing.xs};
`
export const AboutColumnContent = styled.p`
  color: ${({ theme }) => theme.color.grey};
`
